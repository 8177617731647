<template>
    <s-container class="no-bg">
        <div class="s-aisearch text-center">
            <div class="s-container-wrap">
            <img class="pb20" src="@/assets/img/modules/search/ai_search_logo.png"/>
              <a-form-model :model="form"  :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item label="文本信息" >
                <a-textarea :auto-size="{minRows: 10}" v-model="form.test"/>
                </a-form-model-item>
                <a-form-model-item class="s-search-btn-group text-right">
                <a-button type="primary" @click="onSubmit">
                    检索
                </a-button>
                <a-button style="margin-left: 10px;">
                    清空
                </a-button>
                </a-form-model-item>
            </a-form-model>
            </div>
        </div>
    </s-container>
</template>

<script>
    export default {
        name:'AiSearch',
        data() {
            return {
                labelCol: { span: 3 },
                wrapperCol: { span: 21 },
                form:{
                    text:''
                }
            }
        },
        methods: {
            onSubmit(){

            }
        },  
    }
</script>

<style scoped>

</style>